
import SearchResult from "@/components/search/v2/SearchResult"
import ViewAllButton from "@/components/base/button/ViewAllButton"

import { Search } from "@/models/search"
import {
    defineComponent,
    useFetch,
    ref,
    useRoute,
    inject,
} from "@nuxtjs/composition-api"
import sanitizeText from "@/utils/sanitize-text"

export default defineComponent({
    name: "ResultListBlok",
    components: { SearchResult, ViewAllButton },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const channel = props.blok.channel || inject("channelSlug", undefined)
        const route = useRoute()
        const baseType = route.value.name.split("-")[0] //"messe" or "region"
        const isRegionPost = props.blok.type === "region-post"
        if (isRegionPost && baseType === "messe") {
            console.error(
                'Invalid type match. "region-post" is only allowed on region pages'
            )
        }
        const authorType = isRegionPost ? "region" : undefined
        const type = isRegionPost ? "post" : props.blok.type
        const search = ref(
            new Search({
                type,
                channel,
                distance: null,
                location: {
                    slug: route.value.params.slug,
                },
                subdomain: baseType,
                authorType: authorType,
            })
        )
        useFetch(async () => {
            if (search.value.data.length > 0) return
            await search.value.execute()
        })
        return { search }
    },
    methods: {
        parseText(s) {
            if (!s) return
            return sanitizeText(s).replace(/\*([^*]+?)\*/g, "<b>$1</b>")
        },
    },
    computed: {
        searchRoute() {
            return this.search.getRoute()
        },
        category() {
            switch (this.blok.type) {
                case "post":
                case "region-post":
                    return "Neuigkeiten"
                case "apprenticeship":
                    return "Ausbildung"
                case "business":
                    return "Unternehmen"
                case "traineeship":
                    return "Praktikum"
                default:
                    return "Ausbildung"
            }
        },
        contentType() {
            if (["apprenticeship", "traineeship"].includes(this.blok.type))
                return "job"
            if (this.blok.type === "region-post") return "post"
            if (this.blok.type === "business_site") return "business"
            return this.blok.type
        },
    },
})
